<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row justify-content-center">
          <div class="col-6">
            <div class="form-icon form-icon-search">
              <input
                v-model="searchForm"
                v-trim
                v-maxlength
                maxlength='45'
                class="form-control form-control-lg form-icon-input"
                type="text"
                name="search"
                placeholder="企業名で検索"
              />
            </div>
          </div>
          <div class="col-auto">
            <ActionButton class="btn btn-lg btn-main" :handleSubmit="searchCompany" buttonText="検索" />
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:headline>
                グループに追加
              </template>
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox
                    ref="table"
                    :storeModule="storeModule"
                    :labels="labels"
                    :data-table="dataTable"
                    @select-items="handleSelectedItems"
                    :count="shopCount"
                  />
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  :listLength="shopCount"
                  :modulePath="modulePath"
                  :searchConditions="searchConditions"
                  ref="pagination"
                />
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid justify-content-end">
          <li class="listGrid-item">
            <router-link class="btn btn-white" :to="{ name: 'EventShopGroup' }">キャンセル</router-link>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-main" :handleSubmit="add" buttonText="グループに追加する" />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
//mixin
import nav from '@/mixins/nav/shop';
import status from '@/mixins/plugin/status';
import sort from '@/mixins/plugin/sort';
import checkall from '@/mixins/plugin/checkall';
import listStore from '@/mixins/module/listStore';
//component
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import ActionButton from '@/components/ActionButton.vue';
//helpers
import { formatDateAndTime } from '@/helpers/formatData';

export default {
  name: 'EventShopGroupDelete',
  data: function() {
    return {
      pageName: '店舗管理',
      labels: [
        { isCheckbox: true },
        { key: 'name', name: '店舗名' },
        { key: 'company', name: '企業名' },
        { key: 'genreName', name: 'ジャンル' },
        { key: 'registDate', name: '申請日時', sortKey: 'createDate' },
        { key: 'judgeDate', name: '審査処理日時', sortKey: 'judgeDate' },
        { key: 'status', name: 'ステータス', isStatusColumn: true },
      ],
      modulePath: 'shop/getShopList',
      selectedShop: {},
      storeModule: 'shop',
      searchFields: ['company'],
      searchForm: '',
      searchConditions: {
        grouped: 0,
      },
    };
  },
  mixins: [nav, checkall, status, sort, listStore],
  components: {
    TableWithCheckbox,
    PaginationLog,
    ActionButton,
  },
  computed: {
    ...mapGetters({
      shopList: 'shop/shopList',
      shopCount: 'shop/shopCount',
    }),
    groupId() {
      return this.$route.params.id;
    },
    dataTable() {
      return this.shopList.map(item => {
        return {
          ...item,
          status: this.getStatusApprovedItem(item),
          genreName: item.genre?.name || '',
          judgeDate: formatDateAndTime(item.judgeDate),
          registDate: formatDateAndTime(item.registDate),
        }
      })
    }
  },
  methods: {
    handleSelectedItems(items) {
      this.selectedShop = items;
    },
    searchCompany() {
      this.searchConditions.company = this.searchForm;
      this.$refs.pagination.resetPagination();
    },
    async add() {
      if (this.selectedShop?.ids?.length > 0 || this.selectedShop.allFlag) {
        const result = await this.$store.dispatch('group/addShopToGroup', {
          id: this.groupId,
          shops: this.selectedShop.ids,
          allFlag: this.selectedShop.allFlag,
          searchCondition: {
            ...this.searchConditions,
          },
        });
        this.$refs.table.resetCheck();
        if (result) {
          if (result.data.count) {
            this.$message.showSuccess('addShopToGroupSuccess');
          } else {
            this.$message.showError('someoneUpdated');
          }
          this.$refs.pagination.resetPagination();
        }
      } else {
        this.$message.noSelectedItem();
      }
    },
  },
  beforeDestroy() {
    this.$store.commit('shop/SET_SHOP_LIST', []);
  },
};
</script>
